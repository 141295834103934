import VueScrollTo from "vue-scrollto";
import * as bootstrap from "bootstrap";
import {cookieManager} from "@/service/cookieManager";

export async function sendRequest({
									data,
									url,
									method,
									contentType = 'application/x-www-form-urlencoded'
}) {
	try {
		const token = cookieManager.getToken()
		let options = {
			method: method,
			headers: {
				"Content-Type": contentType
			},
		};

		if (token) {
			options.headers['Authorization'] = `Bearer ${token}`;
		}

		const dataToSend = contentType.includes('json') ? JSON.stringify(data) : new URLSearchParams(data);
		options.body = dataToSend;

		const response = await fetch(url, options);

		if (!response.ok) {
			throw new Error("Error en la solicitud: " + response.status);
		}

		return await response.json();


	} catch (error) {
		console.error("Error:", error);
	}
}

export async function sendRequestJson({data, url, method}) {
	const token = cookieManager.getToken()

	let options = {
		method: method,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},

	};

	if (token) {
		options.headers['Authorization'] = `Bearer ${token}`;
	}

	if (data) {
		options.body = JSON.stringify(data);
	}

	try {
		const response = await fetch(url, options);

		if (!response.ok) {
			return response.status;
		}

		return await response.json();

	} catch (error) {
		console.error("Error:", error);
	}
}

const monthsFull = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto',
	'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
];

export const monthsShor = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

const weekdaysFull = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

const weekdaysShort = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sáb'];

export const settingsDatePicker = {
	format: 'dddd, d !de mmmm',
	hiddenName: true,
	formatSubmit: 'yyyy-mm-dd',
	firstDay: 1,
	monthsFull: monthsFull,
	monthsShort: monthsShor,
	weekdaysFull: weekdaysFull,
	weekdaysShort: weekdaysShort,
	today: 'Hoy',
	clear: 'Borrar',
	close: 'Cerrar',
	labelMonthNext: 'Mes siguiente',
	labelMonthPrev: 'Mes anterior',
	labelMonthSelect: 'Selecciona un mes',
	labelYearSelect: 'Selecciona un año',
	klass: {
		highlighted: false,
	},
	//min: [2023, 11, 15],
	min: true,
	max: 60,
	disable: [],
};

export function scrollToVue({element, time = 3500, focus = false}) {
	const options = {
		easing: [0.25, 0.1, 0.25, 1.0],
		duration: time,
		// offset: -(document.getElementById('wewelcome-menu').offsetHeight)
	};
	// const menuElement = document.getElementById('wewelcome-menu');
	// menuElement ? options.offset = -menuElement.offsetHeight : null;
	document.documentElement.style.scrollBehavior = 'auto';
	this.showToast('productSavedCorrectly');

	VueScrollTo.scrollTo(element, options);
	if (focus) {
		element.focus();
	}
}

export function validateEmail(email) {
	const emailPattern = /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,5}|[0-9]{1,3})(\]?)$/;
	return emailPattern.test(email);
}

export function hideAllModals() {
	const modals = document.querySelectorAll('.modal');
	modals.forEach(function (modal) {
		var bootstrapModal = bootstrap.Modal.getInstance(modal); // Get the Bootstrap modal instance
		if (bootstrapModal) {
			bootstrapModal.hide(); // Hide the modal
		}
	});
}

export function hideAllOffcanvas() {
	const modals = document.querySelectorAll('.offcanvas');
	modals.forEach(function (modal) {
		var bootstrapModal = bootstrap.Offcanvas.getInstance(modal); // Get the Bootstrap modal instance
		if (bootstrapModal) {
			bootstrapModal.hide(); // Hide the modal
		}
	});
}

export function showModal(modalId) {
	const modalElement = document.getElementById(modalId);
	if (!modalElement) {
		console.error(`Modal element not found: ${modalId}`);
		return;
	}

	console.log(`Existe modal con id ${modalId}`);
	let modalInstance = bootstrap.Modal.getInstance(modalElement);
	if (!modalInstance) {
		console.log("No existía el modal, creating a new instance");
		modalInstance = new bootstrap.Modal(modalElement, {
			backdrop: 'static',
			keyboard: false
		});
	} else {
		console.log("Ya existía el modal del qr, using existing instance");
	}

	modalInstance.show();
}

export function showOffcanvas(modal) {
	const offcanvasElement = document.getElementById(modal);
	if (offcanvasElement) {
		let offcanvasInstance = bootstrap.Offcanvas.getInstance(offcanvasElement);
		if (!offcanvasInstance) {
			offcanvasInstance = new bootstrap.Offcanvas(offcanvasElement);
		}
		offcanvasInstance.show();

	} else {
		console.error('Modal element not found:');
	}
}

export function hideOffcanvas(id) {
	const offcanvas = document.getElementById(id);
	let offcanvasInstance = bootstrap.Offcanvas.getInstance(offcanvas);
	if (offcanvasInstance) {
		offcanvasInstance.hide();
	}
}


export function filterReservationsByService(reservations) {
	const serviceTimes = {
		Desayuno: {start: 7, end: 12},
		Comida: {start: 13, end: 17},
		Cena: {start: 18, end: 24}
	};

	const services = Object.entries(serviceTimes).map(([serviceName, timeRange]) => {
		const serviceReservations = reservations.filter(reservation => {
			const startTime = parseInt(reservation.startDate.split(':')[0]);
			const isWithinTimeRange = startTime >= timeRange.start && (timeRange.end ? startTime <= timeRange.end : true);
			const isActive = reservation.status !== 0;
			return isWithinTimeRange && isActive;
		});
		return {serviceName, serviceReservations};
	}).filter(service => service.serviceReservations.length > 0);

	return services;
}
/*export function filterReservationsByService(reservations, sortBy = 'hora') {
	const serviceTimes = {
		Desayuno: {start: 7, end: 12},
		Comida: {start: 13, end: 17},
		Cena: {start: 18, end: 24}
	};

	const services = Object.entries(serviceTimes).map(([serviceName, timeRange]) => {
		let serviceReservations = reservations.filter(reservation => {
			const startTime = parseInt(reservation.startDate.split(':')[0]);
			const isWithinTimeRange = startTime >= timeRange.start && (timeRange.end ? startTime <= timeRange.end : true);
			const isActive = reservation.status !== 0;
			return isWithinTimeRange && isActive;
		});

		if (sortBy === 'mesa') {
			serviceReservations = serviceReservations.sort((a, b) => {
				// Extraer la primera mesa antes de la coma
				const tableA = a.table ? String(a.table).split(',')[0].trim().padStart(3, '0') : '';
				const tableB = b.table ? String(b.table).split(',')[0].trim().padStart(3, '0') : '';
				return tableA.localeCompare(tableB);
			});
		} else if (sortBy === 'hora') {
			serviceReservations = serviceReservations.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
		}

		return {serviceName, serviceReservations};
	}).filter(service => service.serviceReservations.length > 0);

	return services;
}*/
// TODO: Creado para recibir del venueModule menuService
// export function filterReservationsByService(reservations, menuServices) {
// 	function timeToMinutes(time) {
// 		const [hours, minutes] = time.split(':').map(Number);
// 		return hours * 60 + minutes;
// 	}
//
// 	const services = menuServices.map(service => {
// 		const serviceStartTime = timeToMinutes(service.startTime);
// 		const serviceEndTime = timeToMinutes(service.endTime);
//
// 		const serviceReservations = reservations.filter(reservation => {
// 			if (!reservation.startDate) return false;
//
// 			const reservationStartTime = timeToMinutes(reservation.startDate);
// 			const isWithinTimeRange = reservationStartTime >= serviceStartTime &&
// 				reservationStartTime <= serviceEndTime;
// 			const isActive = reservation.status !== 0;
// 			return isWithinTimeRange && isActive;
// 		});
//
// 		return { serviceName: service.name, serviceReservations };
// 	});
//
// 	console.log('🖌️🖌️🖌️', services);
// 	return services;
// }

export function arraysAreEqualIgnoringOrder(arr1, arr2) {
	if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
		console.error('Both arguments must be arrays:', arr1, arr2);
		return false;
	}

	if (arr1.length !== arr2.length) {
		return false;
	}

	const copyArr1 = arr1.slice();
	const copyArr2 = arr2.slice();

	while (copyArr1.length > 0) {
		const currentElement = copyArr1.pop();
		const indexInArr2 = copyArr2.findIndex(el => deepCompare(currentElement, el));

		if (indexInArr2 === -1) {
			return false;
		}

		copyArr2.splice(indexInArr2, 1);
	}

	return true;
}

export function deepCompare(obj1, obj2) {
	if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
		return obj1 === obj2;
	}

	const keys1 = Object.keys(obj1).sort();
	const keys2 = Object.keys(obj2).sort();

	if (keys1.length !== keys2.length) {
		return false;
	}

	return keys1.every(key => {
		if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
			return arraysAreEqualIgnoringOrder(obj1[key], obj2[key]);
		}
		return deepCompare(obj1[key], obj2[key]);
	});
}

export function deepCopy(obj) {
	return JSON.parse(JSON.stringify(obj));
}

export function objectsAreEqualIgnoringOrder(obj1, obj2) {
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    console.error('Both arguments must be objects:', obj1, obj2);
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (!arraysAreEqualIgnoringOrder(obj1[key], obj2[key])) {
      return false;
    }
  }

	return true;
}


export const groupItemsBySkuAndChoices = (orders) => {
	const groupedItems = {};

	orders.forEach(order => {
		const visibleItems = order.items.filter(item => item.isVisible);

		visibleItems.forEach(item => {
			const choicesKey = item.choices.map(choice => `${choice.optionTypeId}:${choice.productChoiceId}`).join('|');
			const key = `${item.productSku}|${choicesKey}`;

			if (groupedItems[key]) {
				groupedItems[key].count += item.quantity || 1;
			} else {
				groupedItems[key] = {
					...item,
					choicesKey,
					count: item.quantity || 1,
					choices: item.choices.map(choice => ({
						...choice,
						quantity: choice.quantity || 1
					}))
				};
			}
		});
	});

	return groupedItems;
}

export function normalizeChoices(choices) {
	if (!choices) return [];
	if (Array.isArray(choices)) return choices;
	return Object.entries(choices).sort(([keyA], [keyB]) => keyA.localeCompare(keyB)).map(([key, value]) => ({
		optionId: key,
		chosenIds: Array.isArray(value) ? value.sort() : [value]
	}));
}

export const printChoices = (choices) => {
	return choices
		.map(choice => {
			// Condición para aplicar el formato <strong> solo si la cantidad es mayor que 1
			const quantityDisplay = choice.quantity > 1
				? `<strong>${choice.quantity}</strong>`
				: ``;
			return `${quantityDisplay} ${choice.choice}`;
		})
		.join(', ');  // Unir todos los elementos con una coma
}

