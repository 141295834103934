<template>
	<template v-if="!isLoadingMarkToPreparing">
		<div v-if="!(source !== 'hotTable' && isInHotTable)" :style="{ backgroundImage: pictureMenu ? `url(${pictureMenu})` : {} }" :class="{ 'product-card-employee-new': isNew,'finish': finishProduct, 'started': startedProduct, 'prepared': preparedProduct || isInHotTable, 'content-img': showPictureMenu, 'product-choice': product.choices.length}" @click="handleClickProductCard" class="product-card-employee" :id="`product-${product.productOrderId}`">
			<span v-if="source === 'hotTable' && !finishProduct" class="badge badge-temp badge-secondary">
				<TableIcon style="width: 15px"/> {{ product.reservationInfo ? product.reservationInfo.table : product.table }}
			</span>

			<div class="badges-container" v-if="source !== 'hotTable'">
				<span v-if="startedProduct" class="badge badge-warning badge-process">
					<ChefHatIcon style="width: 13px" fill-color="black"/>
				</span>
				<span class="badge badge-info text-bg-info badge-product-kitchen" v-if="product.dishClassHasChanged">
					<i class="fa-solid fa-backward"></i>
				</span>
				<span v-if="product.isIncludedInMenu" class="badge badge-secondary badge-product-kitchen">
					M
				</span>
				<span v-if="product.units > 1" class="badge badge-secondary badge-product-kitchen">
					x{{ product.units }}
				</span>
				<span v-if="!finishProduct && !preparedProduct" class="badge badge-product-kitchen" :class="isNew ? 'badge-secondary':'badge-primary'">
					{{ elapsedTime }}
				</span>
				<template v-if="source !== 'hotTable'">
					<span class="badge badge-product-kitchen badge-success " v-if="isInHotTable && source !== 'hotTable'"><i class="fa-solid fa-check"></i></span>
					<span class="badge badge-product-kitchen badge-success " v-if="preparedProduct && source !== 'hotTable'"><i class="fa-solid fa-check"></i> <SpinnerIcon/></span>
					<span class="badge badge-product-kitchen badge-success " v-if="finishProduct && source !== 'hotTable'"><i class="fa-solid fa-check-double"></i></span>
				</template>
			</div>

			<div class="product-card-body">
				<div class="product-card-info">
					<div class="product-card-data">
						<div class="product-card-title">
							{{ product.name }}
						</div>
					</div>
					<div class="product-card-choice" v-if="product.choices.length">
						<span v-html="choicesText(product.choices)"></span>
					</div>
				</div>
			</div>
		</div>
	</template>
	<div v-else class="product-card-employee placeholder-glow" :class="{'product-choice': product.choices.length}">
		<div class="badges-container">
			<span class="placeholder col-5">
			</span>
		</div>

		<div class="product-card-body">
			<div class="product-card-info">
				<div class="product-card-data">
					<div class="product-card-title">
						<span class="placeholder col-12">
						</span>
					</div>
				</div>
				<div class="product-card-choice" v-if="product.choices.length">
					<span class="placeholder col-12">
					</span>
					<span class="placeholder col-12">
					</span>
					<span class="placeholder col-12">
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import {mapGetters} from "vuex";
import SpinnerIcon from "@/core/icons/SpinnerIcon";
import ChefHatIcon from "@/core/icons/ChefHatIcon";
import TableIcon from "@/core/icons/TableIcon";

export default {
	name: 'CopilotProductCardKitchen',
	components: {TableIcon, ChefHatIcon, SpinnerIcon},
	data() {
		return {
			isLoadingMarkToPreparing: false,
			showPictureMenu: false,
			pictureMenu: null,
			elapsedTime: '',

			isNew: true,
		};
	},
	inject: {
		dateFormatterService: 'dateFormatterService',
		productOrderRepository: 'productOrderRepository',
	},
	props: {
		product: Object,
		source: {
			type: [String, null],
			default: null
		}
	},
	async mounted() {
		// this.updateElapsedTime();
		await this.checkPictureMenu();
		setInterval(() => {
			this.updateElapsedTime();
		}, 1000);
	},
	beforeMount() {
		this.pictureMenu = `/world/img/${this.resourceId}/product/${this.product.pictureMenu}`;
	},
	beforeUnmount() {

	},
	computed: {
		...mapGetters('venue', ['resourceId']),
		...mapGetters('copilot', ['hotTable']),
		isInHotTable() {
			return this.hotTable.filter(product => product.productOrderId === this.product.productOrderId).length > 0;
		},
		startedProduct() {
			return this.product.statusProductOrder === 2;
		},
		preparedProduct() {
			return this.product.statusProductOrder === 3;
		},
		finishProduct() {
			return this.product.statusProductOrder === 100
		},
	},
	methods: {
		async checkPictureMenu() {
			const url = `/world/img/${this.resourceId}/product/${this.product.pictureMenu}`;
			if (!this.product.pictureMenu) {
				// Si no hay una imagen en el producto, no asignamos una URL y no mostramos la clase
				this.pictureMenu = null;
				this.showPictureMenu = false;
				return;
			}
			try {
				// Usa fetch para verificar si la imagen existe
				const response = await fetch(url);
				if (response.ok) { // Si la respuesta es 200-299
					this.pictureMenu = url;
					this.showPictureMenu = true;
				} else {
					this.pictureMenu = null;
					this.showPictureMenu = false;
				}
			} catch (error) {
				// En caso de error (por ejemplo, si la imagen no existe)
				this.pictureMenu = null;
				this.showPictureMenu = false;
			}
		},
		choicesText(choicesArray) {
			const resultArray = choicesArray
				.map(choice => {
					const unit = choice.quantity > 1 ? `<b>${choice.quantity}</b> ` : '';
					const name = choice.choice ? choice.choice : '';

					return unit + name;
				})
				.filter(text => text.trim() !== '');

			if (resultArray.length === 0) {
				return '';
			}

			const result = resultArray.join(', ');

			console.log(result);
			return result ? result + '.' : '';
		},
		updateElapsedTime() {
			if (!this.product || !this.product.createdDateTime) {
				return;
			}

			const now = new Date();

			const createdTime = new Date(this.product.createdDateTime);

			const differenceInMs = now - createdTime;

			if (differenceInMs > 300000) {
				this.isNew = false;
			}

			this.elapsedTime = this.dateFormatterService.formattedTimeChronometer(this.product.createdDateTime);
		},
		async handleClickProductCard() {
			console.log(`Se ha hecho click en el producto ${this.product.name} con status ${this.product.statusProductOrder}`, this.source, this.product);

			const data = { productOrderId: this.product.productOrderId };
			this.isLoadingMarkToPreparing = true;

			try {
				switch (this.product.statusProductOrder) {
					case 1:
						await this.productOrderRepository.markAsPreparingProductOrder(data);
						break;
					case 2:
						await this.productOrderRepository.addProductOrderToHotTable(data);
						break;
					case 7:
						await this.productOrderRepository.removeProductOrderToHotTable(data);
						break;
					default:
						console.warn(`Estado no manejado: ${this.product.statusProductOrder}`);
				}
			} catch (error) {
				console.error("Error al procesar el producto:", error);
			} finally {
				this.isLoadingMarkToPreparing = false;
			}
		},

	}
}
</script>
