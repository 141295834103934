class CookieManager {
	constructor() {
		this.token = this.getCookie('token');
		this.reservationMenuId = this.getCookie('reservationMenuId');
		console.log('Instanciado cookieManage')
	}

	setCookie(name, value) {
		let expires = "";
		// Establecer una fecha de vencimiento muy lejana en el futuro (por ejemplo, 100 años)
		const oneHundredYearsInSeconds = 100 * 365 * 24 * 60 * 60;
		let date = new Date();
		date.setTime(date.getTime() + oneHundredYearsInSeconds * 1000); // Convertir a milisegundos
		expires = "; expires=" + date.toUTCString();

		document.cookie = name + "=" + (value || "") + expires + "; path=/";
	}

	getCookie(name) {
		let nameEQ = name + "=";
		let ca = document.cookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == ' ') c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		}
		return null;
	}

	clearCookie(name) {
		document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
	}

	getReservationMenuId(){
		return this.reservationMenuId;
	}

	setReservationMenuId(reservationMenuId){
		this.setCookie('reservationMenuId', reservationMenuId);
		this.reservationMenuId = reservationMenuId;
	}

	clearReservationMenuId(){
		this.clearCookie('reservationMenuId');
		this.reservationMenuId = '';
	}

	getToken(){
		return this.token;
	}

	setToken(token){
		this.setCookie('token', token);
		this.token = token;
	}

	clearToken(){
		this.clearCookie('token');
		this.token = '';
	}
}

export const cookieManager = new CookieManager();
