<template>
	<!--  Offcanvas historico de pedidos	-->
	<div v-if="reservation" class="offcanvas offcanvas-bottom" tabindex="-1" :id="'offcanvas-historic-account-' + reservation.id" :aria-labelledby="'collapseHistoricOrders' + reservation.id">
		<div class="offcanvas-header mb-0">
			<div class="offcanvas-title h4" id="offcanvasBottomLabel">Cuenta mesa {{ reservation.table }}</div>
			<button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
		</div>

		<div class="offcanvas-body p-3">
			<div class="row mb-4 row-gap-2">
				<div class="col-12">
					<button v-if="showTicketButton" @click="handlePrintAccount()" class="btn btn-light w-100">
						<span class="process-button"><i class="fa-regular fa-file-pdf"></i> Ver Ticket</span></button>
				</div>
				<div class="col-5">
					<button class="btn btn-light btn-outline-primary w-100" :class="{ active: viewMode === 'unified' }" @click.stop="viewMode = 'unified'">Cuenta total</button>
				</div>
				<div class="col-7">
					<button class="btn btn-light btn-outline-primary w-100" :class="{ active: viewMode === 'user' }" @click.stop="viewMode = 'user'">Cuenta por pedido</button>
				</div>
			</div>

			<transition v-if="processedCartSessionOrders.length" name="fade" :duration="300" mode="out-in">
				<div v-if="viewMode === 'user'" key="user">
					<div class="orders-history " style="background-color: var(--bs-body-bg)" v-for="cartSessionOrder in processedCartSessionOrders" :key="cartSessionOrder.cartSessionOrderId">
						<div class="list-name">
							<div class="fw-bold">
								<i class="fas fa-utensils"></i>{{ cartSessionOrder.userName }}
							</div>
							<div>
              <span>
                <i class="fas fa-clock"></i>{{ dateFormatterService.formattedTime(cartSessionOrder.createdDateTime) }} &nbsp; <i class="fas fa-check"></i>{{ dateFormatterService.formattedTime(cartSessionOrder.acknowledgedDateTime) }}
              </span>
							</div>
						</div>
						<div class="extras-products-list">
							<OrdersProductCard :order="order" :reservation="this.reservation" :source="'orders'" v-for="order in  groupedItems(cartSessionOrder)" :key="order.cartSessionOrderId"/>
						</div>
						<div class="vr-horizontal"></div>
					</div>
				</div>
				<div v-else key="unified">
					<div class="extras-products-list">
						<OrdersProductCard :order="order" v-for="order in historicUnification" :key="order.productSku"/>
					</div>
				</div>
			</transition>
			<div v-else>
				No hay historial de pedidos
			</div>
		</div>

		<div class="offcanvas-footer">
			<DismissButton :is-modal="false"/>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";

import {groupItemsBySkuAndChoices} from "@/utils";

import DismissButton from "@/core/DismissButton";
import OrdersProductCard from "@/components/ordersProductCard/OrdersProductCard";

export default {
	name: "CopilotOffcanvasHistoricAccountReservation",
	inject: {
		dateFormatterService: 'dateFormatterService',
	},
	data() {
		return{
			viewMode: 'unified',
		}
	},
	components:{
		OrdersProductCard,
		DismissButton

	},
	computed:{
		...mapGetters('venue', ['resourceId']),
		...mapGetters('config', ['weWelcomUrl']),
		...mapGetters('copilot',['reservation']),

		cartSessionOrders() {
			return this.reservation.cartSession ? this.reservation.cartSession.productOrders : [];
		},
		showTicketButton() {
			return this.resourceId === "wewelcom";
		},
		processedCartSessionOrders() {
			return this.cartSessionOrders
				.filter(order => order.cartSessionStatus > 1)
		},
		historicUnification() {
			const groupedItems = groupItemsBySkuAndChoices(this.processedCartSessionOrders);
			console.log('antes de arrayProducts:', groupedItems);

			const arrayProducts = Object.values(groupedItems).map(item => ({
				productSku: item.productSku,
				name: item.name,
				quantity: item.count,
				category: item.category,
				pictureGeneral: item.pictureGeneral,
				pictureMhd: item.pictureMhd,
				units: item.units > 1 ? item.units : null,
				type: item.type,
				isVisible: item.isVisible,
				choices: item.choices.map(choice => ({
					...choice,
					quantity: choice.quantity || 1
				})),
				choicesKey: item.choicesKey
			}));

			console.log('arrayProducts:', arrayProducts);

			return arrayProducts.filter(product => product.isVisible);
		},
	},
	mounted() {
	},
	methods: {
		async handlePrintAccount() {
			console.log("En la venue " + this.resourceId + " Vamos a imprimir el ticket de " + this.reservation.id);

			let openTab = true;

			if (openTab) {
				window.open(this.weWelcomUrl + "/world/api/reservation/ticket/" + this.reservation.id, '_blank');

			} else {
				try {
					console.log("Fetching PDF for reservation " + this.reservation.id);

					// Fetch the PDF as a Blob
					const response = await fetch(`${this.weWelcomUrl}/world/api/reservation/ticket/${this.reservation.id}`, {
						method: 'GET',
						headers: {
							'Accept': 'application/pdf',
						}
					});

					if (response.ok) {
						const blob = await response.blob();

						this.pdfUrl = URL.createObjectURL(blob);
					} else {
						console.error('Error fetching PDF');
					}
				} catch (error) {
					console.error('Error:', error);
				}
			}
		},
		groupedItems(order) {
			return groupItemsBySkuAndChoices([order]);
		},

	}
}
</script>
