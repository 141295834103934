<template>
	<div id="modal-sit-in" class="offcanvas offcanvas-bottom fade" tabindex="-1">
		<div class="offcanvas-header mb-0">
			<div id="modal-sit-in-label" class="offcanvas-title h4"><SittingIcon/> Sit-In</div>
			<button aria-label="Close" class="btn-close" data-bs-dismiss="offcanvas" type="button"></button>
		</div>
		<div class="offcanvas-body p-3">
			<form>
				<copilot-sitting-select-table @update-selected-tables="handleSelectedTablesUpdate" ref="sittingSelectTable"></copilot-sitting-select-table>

				<div class="input-group mt-3">
					<span class="input-group-text"><i class="fas fa-user-plus"></i></span>
					<input id="input-pax" v-model="walkInPax" aria-label="" class="form-control"  placeholder="Nº de personas:" type="number">
				</div>

				<div v-if="showErrorMessageWalkIn" id="walk-in-response-collapse" class="alert alert-danger" role="alert">
					{{ errorMessageWalkIn }}
				</div>

				<div class="content-center">
					<button v-html="createWalkInButtonText" :disabled="walkInButtonDisabled" class="btn btn-primary" @click="handleNewWalkinButton"></button>
				</div>
			</form>
		</div>
		<div class="offcanvas-footer">
			<DismissButton :is-modal="false" :on-click="closeModal"/>
		</div>
	</div>
</template>
<script>


import DismissButton from "@/core/DismissButton";
import {hideAllModals, hideAllOffcanvas, hideOffcanvas} from "@/utils";
import SittingIcon from "@/core/icons/SittingIcon";
import CopilotSittingSelectTable from "@/components/copilotSittingSelectTable/CopilotSittingSelectTable";
import store from "@/store";
import {walkingService} from "@/service/walkingService";

export default {
	name: 'CopilotSitting',
	components: {CopilotSittingSelectTable, DismissButton, SittingIcon},
	inject: {
		reservationRepository: 'reservationRepository',
		copilotRepository: 'copilotRepository',
	},
	props: {},
	data() {
		return {
			walkInPax: null,
			createWalkInButtonText: 'Crear Sit-In',
			showErrorMessageWalkIn: false,
			errorMessageWalkIn: null,
			selectedTables: []
		};
	},
	computed: {
		walkInButtonDisabled(){
			return !this.selectedTables.length || !this.walkInPax
		},
	},

	beforeMount() {
	},
	mounted() {
	},
	beforeUnmount() {
	},
	methods: {
		closeModal(){
			hideAllModals();
		},
		handleSelectedTablesUpdate(newSelectedTables) {
			this.selectedTables = newSelectedTables;
		},
		async handleNewWalkinButton(){
			event.preventDefault()
			this.createWalkInButtonText = 'Creando Sit-In...<i class="fas fa-spinner fa-spin"></i>'
			const tables = this.selectedTables.map(table => table.table_id)
			const data = {pax: this.walkInPax, tables};
			console.log(data);


			const service = walkingService(this.$store);
			let resultWalkin = await service.create(data);
			console.log("El resultado de crear el walkin", resultWalkin);

			if(resultWalkin.result === 0){
				store.commit("copilot/setToast", {toast:{toastMessage: "Se ha creado el sitting correctamente", showToast:true, idToast: "correctlyToast"}});
				this.$refs.sittingSelectTable.clearSelectedTables();
				this.walkInPax = null;
				hideOffcanvas('modal-sit-in');
			}else{
				store.commit("copilot/setToast", {toast:{toastMessage: 'No se ha podido crear el sit-in.', showToast:true, idToast: "incorrectlyToast"}});
				hideAllModals();
				hideAllOffcanvas();
			}
			this.createWalkInButtonText = 'Crear Sit-In';
		},
	}

}
</script>
