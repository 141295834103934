<div v-if="reservation.status !== 0" class="service-reservation-container" :id="'reservation-' + reservation.id" :data-bs-parent="`childrenCollapseService${serviceName}`">
	<div class="service-reservation" :class="{[reservationStatus(reservation)]:true,'table-dubbing': hasDubbing}" @click="openCollapse(reservation.id)" role="button">
		<!--  primera linea de datos de la reserva	-->
		<div class="row service-reservation-data">
			<div class="col-3">
				<div class="service-meta">
					<span class="icon-reservation">
						<i v-if="isWalkin" class="fas fa-walking"></i>
						<SittingIcon fill-color="white" v-if="isSitting"/>
						<i v-if="isReserve" class="fas fa-qrcode"></i>
					</span>
					<div>{{ reservation.startDate }}h</div>
				</div>
			</div>
			<div class="col-2">
				<button :disabled="paxUpdateDisabled" class="btn btn-light w-100 btn-sm" @click="openModalUpdatePax(reservation)">
					<i class="fas fa-user"></i> {{ reservation.pax }}
				</button>
			</div>
			<div class="col-7">
				<copilot-card-select-table :reservation="reservation"></copilot-card-select-table>
			</div>
		</div>

		<div class="row service-reservation-data">
			<div class="col-3">
				<div class="service-meta" v-if="reservationReview">
					<i class="fas fa-star" style="color: var(--bs-secondary)"></i>
					<b>{{ reservationReview }}</b>
				</div>
			</div>
			<div class="col-9">
				<div class="service-meta" v-if="reservation.paymentInfo.payments && reservation.paymentInfo.payments.length">
					Pago:
					<span v-for="payment in reservation.paymentInfo.payments">
						<span v-if="payment && payment.methodName === 'Efectivo' && payment.type === 'Local'"><i :class="{'text-success':payment.status === 100}" class="fa-regular fa-money-bill-1"></i></span>
						<span v-if="payment && payment.methodName === 'Tarjeta' && payment.type === 'Local'"><i :class="{'text-success':payment.status === 100}" class="fa-solid fa-credit-card"></i></span>
						<span v-if="payment && payment.type === 'Online'"><i :class="{'text-success':payment.status === 100}" class="fa-solid fa-mobile-screen-button"></i></span>
					</span>
				</div>
			</div>

			<div class="col-12" v-if="reservations.status === 7">
				La reserva se moverá a 'Finalizadas' automáticamente.
			</div>
		</div>

		<span v-if="pendingOrders && pendingOrders.length > 0 && isReservationRequest" class="alert-reserve order">
			Pedido
		</span>

		<span v-if="notifications[0] && notifications[0].reservation.id === reservation.id" class="alert-reserve text-dark process-pay">
			Cuenta pedida / {{ paymentMethodName(notifications[0].message) }}
		</span>
		<!--		<span class="alert-reserve account" v-if="reservation.isPaid">-->
		<!--			Pagado con-->
		<!--		</span>-->
		<!--		<span v-if="reservation.status === 10" class="alert-reserve account">-->
		<!--			Pagado Tarjeta-->
		<!--		</span>-->

		<div class="collapse" :id="'collapse' + reservation.id">
			<div class="collapse-body mb-0 gap-3 container">
				<!--Pedidos-->
				<div v-if="pendingOrders && pendingOrders[0] && isReservationRequest" class="order-container order-container-copilot">
					<div class="order-data">
						<div class="order-data-body">
							<span>
								<i class="fas fa-utensils"></i> <b>{{ pendingOrders[0].userName }}</b>
							</span>
							<span>
								<i class="fas fa-clock"></i> {{ elapsedTime }}
							</span>
						</div>
					</div>
					<!--					<div class="products-list-content">-->
					<!--						<orders-product-card :order="order" v-for="order in pendingOrders[0].items" :key="order.productSku"></orders-product-card>-->
					<!--					</div>-->
					<div class="products-list-content">
						<orders-product-card :order="order" v-for="order in pendingOrders[0].items" :key="order.productSku"></orders-product-card>
					</div>
					<button @click="handleProcessOrder(pendingOrders[0].cartSessionOrderId)" class="btn btn-success w-100">
						<span class="process-button" v-html="buttonTextOrder"></span></button>
				</div>

				<!--  segunda linea de datos de la reserva	-->
				<div class="row service-reservation-data">
					<div class="col-12">
						<i class="fas fa-users me-1"></i>
						<span v-if="reservation.reservationUsers">
							<span v-html="invitationText"></span>
						</span>
						<span v-else>No hay invitados</span>
					</div>

				</div>
				<div class="row g-2">
					<div class="col-6">
						<button v-if="isReservationStatusStart && !isReservationCancelled && reservation.finishedDateTime === null && !isReservationFinish" class="btn btn-light w-100 " @click.stop="openComanderMenu" type="button">
							Comandero
						</button>
					</div>
					<div class="col-6">
						<button
							class="btn btn-secondary w-100"
							@click.stop="reservation.id.includes('WALKIN') ? openQRInvitation(reservation) : handleOptionClick('camera', reservation.id)">
							<span v-if="reservation.id.includes('WALKIN')">Ver</span>
							<span v-else><i class="fas fa-camera"></i></span>QR
						</button>
					</div>
					<div class="col-6">
						<button v-if="!reservation.finishedDateTime && !reservation.cancelledDateTime && !isNoShow && reservation.menuAccessEnabled === 0" class="btn btn-light w-100" @click="handelFinishReservation(1)">no show</button>
						<button v-if="!reservation.finishedDateTime && !reservation.cancelledDateTime && !isNoShow && reservation.menuAccessEnabled === 1 && !isReservationFinish" class="btn btn-light w-100" @click="openModalFinishReservation(reservation)">Cuenta pedida</button>
						<button v-else v-if="isReservationStatusStart || isNoShow" class="btn btn-light w-100" @click="handelReactivateReservation">
							<span v-html="reactivateReservationTextButton"></span></button>
					</div>
					<div class="col-6">
						<button class="btn btn-light w-100" v-if="isReservationStatusStart && !isReservationCancelled" type="button" @click="openOffcanvasHistoricAccount(reservation)">
							Ver Cuenta
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="copilot-arrow">
			<div class="arrow btn btn-light" :id="'containerArrow'+reservation.id">
				<div class="service-meta guests text-truncate">
					<template v-if="viewListUsers">
						<i class="fas fa-users me-1"></i>
						<span v-if="reservation.reservationUsers">
								<span v-html="invitationText"></span>
							</span>
						<span v-else>No hay invitados</span>
					</template>
				</div>
				<i :id="'iconArrow'+reservation.id" class="fas fa-chevron-down"></i>
			</div>
		</div>
	</div>
</div>
