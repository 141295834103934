<template>
	<div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvas-products-edit-status">
		<div class="offcanvas-header mb-0">
			<div class="offcanvas-title h4 m-0">Disponibilidad de platos</div>
			<button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
		</div>
		<div class="offcanvas-body p-3">
			<div class="input-group">
				<span class="input-group-text" id="basic-addon1"><i class="fas fa-search"></i></span>
				<input
					ref="searchInput"
					type="text"
					class="form-control"
					placeholder="Escribe aquí para buscar tu producto"
					v-model="searchQuery"
					@input="search"
				>
				<span class="input-group-text" style="cursor: pointer;" @click="clearInputSearch">
					<i class="fa-solid fa-circle-xmark"></i>
				</span>
			</div>
			<p class="text-muted mb-3">
				Recuerda: al final del día, todos los platos estarán disponibles.
			</p>
			<div v-for="menu in filteredProducts" :key="menu.id" :id="menu.id ">
				<div v-for="category in menu.menuCategories" :key="category.id" :id="category.id + '-' + category.name">
					<h2>
						{{ category.name }}
					</h2>
					<div v-for="product in category.products" :key="'card-availability' + product.id" class="extras-list-content mb-2">
						<copilot-product-card-availability-manager :product="product"></copilot-product-card-availability-manager>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CopilotProductCardAvailabilityManager
	from "@/components/CopilotProductCardAvailabilityManager/CopilotProductCardAvailabilityManager";
import {mapGetters} from "vuex";

export default {
	name: "ProductAvailabilityManager",
	components: {
		CopilotProductCardAvailabilityManager,
	},
	data(){
		return {
			searchQuery: '',

		}
	},
	computed: {
		...mapGetters('menu', ['menusVenue']),
		filteredProducts() {
			if (!this.searchQuery) return this.menusVenue;
			return this.filterMenus(this.normalizeText(this.searchQuery));
		}
	},
	mounted() {
		console.log('Los productos desde el manager', this.menuProducts)
	},
	methods:{
		search() {
			clearTimeout(this.searchTimeout);
			this.searchTimeout = setTimeout(() => {
				this.$forceUpdate(); // Fuerza la actualización para recalcular los productos filtrados
			}, 300);
		},

		filterMenus(query) {
			return this.menusVenue.map(category => {
				const newCategory = {
					...category,
					menuCategories: category.menuCategories.map(subCategory => {
						const newSubCategory = {
							...subCategory,
							products: subCategory.products.filter(product => this.matchesQuery(product.name, query))
						};
						return newSubCategory.products.length ? newSubCategory : null;
					}).filter(Boolean)
				};
				return newCategory.menuCategories.length ? newCategory : null;
			}).filter(Boolean);
		},

		matchesQuery(name, query) {
			return this.normalizeText(name).includes(query);
		},

		normalizeText(text) {
			return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
		},

		clearInputSearch() {
			this.searchQuery = '';
			this.search();
		},
	}
}
</script>
