<template>
	<label class="product-card-client product-card-client-list" :for="`option_checkbox_${product.name}-${product.productOrderId}`">
		<div class="product-card-body">
			<img v-if="showPictureMenu !== null && imageUrl()" class="product-card-img " :src="imageUrl()" @error="handleImageError" alt="...">
			<div class="product-card-info">
				<div class="product-card-data">
					<div class="product-card-title">
						{{ product.name }}
					</div>
				</div>
			</div>
			<div class="product-card-btns flex-column">
				<select v-model="availabilityLocal" @change="handleChange" :class="selectClass"
						class="form-select" style="width: 10rem;"
						aria-label="Default select example">
					<option selected value="1">Disponible</option>
					<option value="0">No Disponible</option>
				</select>

				<div class="form-check"  v-if="!isUpdatingProduct">
					<input class="form-check-input" :id="`check-box-recommender-chef-${product.name}-${product.productOrderId}`" type="checkbox" @change="handleCheckboxChange" v-model="isChecked">
					<label class="form-check-label ms-1" :for="`check-box-recommender-chef-${product.name}-${product.productOrderId}`">
						Sugerencia del chef
					</label>
				</div>
				<span v-else>Actualizando... <SpinnerIcon /></span>
			</div>

		</div>
	</label>
</template>

<script>
import {mapGetters} from "vuex";
import SpinnerIcon from "@/core/icons/SpinnerIcon";


export default {
	name: 'CopilotProductCardAvailabilityManager',
	components: {SpinnerIcon},
	inject: {
		copilotRepository: 'copilotRepository',
	},
	props: {
		product: {
			type: Object,
			required: true,
			default: null
		},
	},
	data() {
		return {
			isUpdatingProduct: false,
			showPictureMenu: null,
			availabilityLocal: 1,
			isChecked: false
		};
	},
	computed: {

		...mapGetters('venue', ['resourceId', 'urlVenue']),
		availability() {
			return this.product.menuCategoryProductInfo.availability;
		},
		productImg() {
			return `/world/img/${this.resourceId}/product/${this.product.pictureMenu}`;
		},
		productFoodImg() {
			return `/world/img/${this.resourceId}/product/${this.product.pictureMenu}`;
		},
		selectClass() {
			return Number(this.availabilityLocal) === 1 ? 'availability' : 'not-availability';
		}
	},
	watch: {
		product: {
			immediate: true,
			handler() {
				this.availabilityLocal = this.availability;
				this.isChecked = this.product.topChoice === "SI";
			},
		},
	},
	mounted() {
		this.checkImage();
		this.availabilityLocal = this.availability
		this.isChecked = this.product.topChoice === "SI";
	},
	methods: {
		imageUrl() {
			return this.productImg;
		},
		checkImage() {
			const img = new Image();
			img.src = this.productImg;

			img.onerror = () => {
				this.showPictureMenu = null;
			};

			this.showPictureMenu = true
		},
		handleImageError() {
			this.showPictureMenu = null;
		},
		async handleChange() {
			this.isUpdatingProduct = true;
			const previousValue = Number(this.availabilityLocal) === 0 ? 1 : 0
			const data = {
				productSku: this.product.sku,
				menuCategoryId: this.product.menuCategoryProductInfo.menuCategoryId,
				status: this.availabilityLocal
			}
			const resultUpdateAvailability = await this.copilotRepository.updateAvailabilityProduct(data);
			if (resultUpdateAvailability.result && resultUpdateAvailability.result < 0) {
				this.availabilityLocal = previousValue
			}
			this.isUpdatingProduct = false;
		},

		async handleCheckboxChange() {
			this.isUpdatingProduct = true;
			this.previousChecked = !this.isChecked;
			const data = {
				productSku: this.product.sku,
				topChoice:this.isChecked
			}
			const resultUpdateTopChoice = await this.copilotRepository.updateTopChoiceProduct(data);
			if (resultUpdateTopChoice.result && resultUpdateTopChoice.result < 0) {
				this.isChecked = this.previousChecked
			}
			this.isUpdatingProduct = false;
		}
	}
}
</script>
