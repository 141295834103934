<div>
	<h2>
		{{ sectionTitle }} <span class="time"><i class="fas fa-clock"></i> {{ elapsedTime }}</span>
	</h2>

	<!--  Placeholder	-->
	<div v-if="isLoadingOrder" class="order-container order-container-copilot">
		<div class="order-data placeholder-glow">
			<div class="order-data-header">
				<span class="placeholder col-5"></span>
				<span class="placeholder col-5"></span>
			</div>
		</div>

		<div class="content-list-order">
			<div class="card product-list">
				<div class="card-info placeholder-glow">
					<span class="placeholder col-10"></span>
					<span class="placeholder col-2"></span>
				</div>
			</div>
			<div class="card product-list">
				<div class="card-info placeholder-glow">
					<span class="placeholder col-10"></span>
					<span class="placeholder col-2"></span>
				</div>
			</div>
			<div class="card product-list">
				<div class="card-info placeholder-glow">
					<span class="placeholder col-10"></span>
					<span class="placeholder col-2"></span>
				</div>
			</div>
		</div>

		<button class="btn btn-success w-100 disabled placeholder col-6" aria-disabled="true"></button>
	</div>

	<div v-if="notificationType === 'newOrder'" class="order-container order-container-copilot fadeOutBackground">
		<div class="order-data">
			<div class="order-data-header">
				<div v-if="pendingOrders[0].reservationName">
					<i class="fa-solid fa-qrcode"></i>
					{{ pendingOrders[0].reservationName }}
				</div>
				<div>
					<table-icon fill-color="black"></table-icon>
					{{ pendingOrders[0].reservationTable }} <div class="vr mx-1"></div> {{ pendingOrders[0].reservationZone }}
				</div>

				<div>
					<i class="fas fa-utensils"></i> {{ pendingOrders[0].userName }}
				</div>
			</div>
		</div>
		<!-- Display the client message if it exists -->
		<div v-if="pendingOrders[0].clientMessage" class="client-message">
			<strong>Petición del cliente:</strong>
			<p>{{ pendingOrders[0].clientMessage }}</p>
		</div>
		<div class="products-list-content">
			<orders-product-card v-for="order in pendingOrders[0].items" source="notification" :order="order" :key="order.productSku"></orders-product-card>
		</div>

		<div class="my-3">
			<button @click="handleProcessOrder(pendingOrders[0].cartSessionOrderId)" class="btn btn-success w-100">
				<span class="process-button" v-html="buttonTextOrder"></span></button>
		</div>
	</div>

	<div v-if="notificationType === 'readyOrder'" class="order-container order-container-copilot fadeOutBackground">
		<div class="order-data">
			<div class="order-data-header">
				<div>
					<table-icon fill-color="black"/>
					{{ pendingOrders[0].reservationTable }} / {{ pendingOrders[0].reservationZone }}
				</div>
				<div>
					<i class="fas fa-utensils"></i> {{ pendingOrders[0].userName }}
				</div>
			</div>
		</div>
		<div class="products-list-content">
			<orders-product-card :order="order" v-for="order in pendingOrders[0].items" :key="order.productSku" source="readyOrder"></orders-product-card>
		</div>

		<button @click="handleProcessOrder(pendingOrders[0].cartSessionOrderId)" class="btn btn-success w-100">
			<span class="process-button" v-html="buttonTextOrder"></span></button>
	</div>

	<div v-if="pendingOrders && pendingOrders.length > 1" class="alert alert-order" role="alert">
		<i class="fas fa-bell"></i> <span> +{{ pendingOrders.length - 1 }} pedidos</span>
	</div>
</div>
