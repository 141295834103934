<div class="navbar-bottom-container" v-if="!isOpenModalSearch" :style="isCopilotRoute ? { bottom: '62px', position : 'absolute' } : {}">
	<div class="navbar-btns-action float-btns">
		<button v-if="isValidReservation" :class="{ 'view-btn visible': isVisibleBtnAccount, 'invisible': isInvisibleBtnAccount }" type="button" @click="openModalAccount" class="btn  btn-primary ">
			<i class="fa-solid fa-hand-holding-dollar"></i> Pedir Cuenta
		</button>
		<div v-else></div>
		<div style="display: flex; flex-direction: column; gap: .5rem">
			<div>
				<!--		TODO: no borrar muestra y oculta al scroll  :class="{ 'hidden-button': !scrollButtonVisible, 'view-btn': scrollButtonVisible}" 		-->
				<a href="#" @click.prevent="scrollToTop()" :class="{ 'hidden-button': !scrollButtonVisible, 'view-btn': scrollButtonVisible}" class="btn shopping-cart border-secondary btn-primary btn-rounded">
					<i class="fas fa-arrow-up"></i>
				</a>
			</div>
			<button v-if="isValidReservation" @click="openModalOrders" :class="{ 'hidden-button': totalProducts === 0, 'view-btn': totalProducts > 0}" class="btn shopping-cart border-secondary btn-secondary btn-rounded">
				<span v-if="showNewProductAlert" class="alert-new-product">
					  {{ lastAction === 'add' ? 'Producto añadido' : 'Producto eliminado' }}
				</span>
				<i class="fas fa-shopping-cart"></i>
				<span v-if="totalProducts !== 0" class="badge badge-secondary badge-cart">{{ totalProducts }}</span>
			</button>
		</div>
	</div>
</div>
