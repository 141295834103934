<template>
	<div class="product-card-client product-card-client-list" v-for="option in options" :key="option.id">
		<div v-if="isStackable" class="product-card-body">
			<div class="product-card-info">
				<div class="product-card-data">
					<div class="product-card-title">{{ option.name }}</div>
				</div>
			</div>
			<div class="product-card-btns">
				<div class="btns">
					<button v-if="countOptionsInSelectedOptions(option.id) > 0" @click="subtractOption(option.id)" class="btn btn-secondary btn-circle">
						<i class="fas" :class="countOptionsInSelectedOptions(option.id) === 1 ? 'fa-trash' : 'fa-minus'"></i>
					</button>
					<div v-if="countOptionsInSelectedOptions(option.id) > 0" class="product-card-unit">
						{{countOptionsInSelectedOptions(option.id)}}
					</div>
					<button :disabled="selectedOptions.length >= max" @click="addOption(option.id)" class="btn btn-secondary btn-circle">
						<i class="fas fa-plus"></i>
					</button>
				</div>
			</div>
		</div>
		<div v-else class="product-card-body flex-row form-check form-check-reverse">
			<label class="product-card-info form-check-label" :for="`option_checkbox_${product.sku}-${option.id}`">
				<span class="product-card-data">
					<div class="product-card-title">
					{{ option.name }}
					<span v-if="option.priceSupplement" class="badge badge-plus">+ {{ option.priceSupplement }} €</span>
					</div>
				</span>
			</label>
			<div class="product-card-btns">
				<input class="form-check-input" type="checkbox" :id="`option_checkbox_${product.sku}-${option.id}`" v-model="selectedOptions" :value="option.id" @change="handleCheckboxChange(option.id)">
			</div>
		</div>
	</div>

</template>

<script>
import { reactive } from 'vue';
import store from "@/store";

export default {
	name: 'MenuProductCardOptions',
	inject: {},
	props: {
		options: Array,
		product: {
			type: Object,
			required: true
		},
		max: {
			type: Number,
			required: true,
			default: 0
		},
		min: {
			type: Number,
			required: true,
			default: 0
		},
		stackable: {
			type: Number,
			required: true,
			validator: (value) => {
				return value === null || Number.isInteger(value);
			}
		}
	},
	emits: ['update-options'],
	beforeMount() {
	},
	mounted() {
	},
	components: {},
	data() {
		return {
			selectedOptions: reactive([]),
		}
	},
	computed: {
		isStackable(){
			return this.stackable === 1;
		},
		isOptionsWithinRange(){
			return this.selectedOptions.length >= this.min && this.selectedOptions.length <= this.max
		}
	},
	methods: {
		countOptionsInSelectedOptions(optionId){
			return this.selectedOptions.reduce((contador, item) => {
				return contador + (item === optionId ? 1 : 0);
			}, 0);
		},
		clearSelectedOptions(){
			this.selectedOptions = [];
		},
		handleCheckboxChange(optionId) {
			console.log("El  opciones seleccionadas al ejecutar el cambio", this.selectedOptions);
			let selectedOptionsArray = [...this.selectedOptions];

			//Si ya estaba lo eliminamos
			if (selectedOptionsArray.filter(optId => optId === optionId).length === 2) {
				this.selectedOptions = selectedOptionsArray.filter(optId => optId !== optionId);
				this.isOptionsWithinRange ? this.$emit('update-options',[...this.selectedOptions]) : this.$emit('update-options', null);
				return console.log("El array de opciones seleccionadas Si ya estaba lo eliminamos después del cambio", this.selectedOptions);
			}

			//Si no estaba y no se ha alcanzado el maximo
			if (selectedOptionsArray.filter(optId => optId === optionId).length === 1 && selectedOptionsArray.length > this.max + 1) {
				this.selectedOptions = selectedOptionsArray;
				this.isOptionsWithinRange ? this.$emit('update-options', [...this.selectedOptions]) : this.$emit('update-options', null);
				return console.log("El array de opciones seleccionadas Si no estaba y no se ha alcanzado el maximo después del cambio", this.selectedOptions);
			}

			//Si no estaba y se ha alcanzado el maximo
			if (selectedOptionsArray.filter(optId => optId === optionId).length === 1 && selectedOptionsArray.length === this.max + 1) {
				if(this.max > 1){
					this.selectedOptions = selectedOptionsArray.filter(optId => optId !== optionId);
					return store.commit("copilot/setToast", {toast:{toastMessage: 'Has alcanzado el número máximo de opciones permitidas. Deselecciona una opción antes de seleccionar una nueva.', showToast:true, idToast: "incorrectlyToast"}});
				}
				selectedOptionsArray.shift();
				this.selectedOptions = selectedOptionsArray;
				return console.log("El array de opciones seleccionadas Si no estaba y se ha alcanzado el maximo después del cambio", this.selectedOptions);
			}

			this.isOptionsWithinRange ? this.$emit('update-options', [...this.selectedOptions]) : this.$emit('update-options', null);

		},
		addOption(optionId){
			let selectedOptionsArray = [...this.selectedOptions];
			selectedOptionsArray.push(optionId);
			this.selectedOptions = selectedOptionsArray;
			this.isOptionsWithinRange ? this.$emit('update-options', [...this.selectedOptions]) : this.$emit('update-options', null);
		},
		subtractOption(optionId){
			let selectedOptionsArray = [...this.selectedOptions];
			const index = selectedOptionsArray.indexOf(optionId);
			if (index !== -1) {
				selectedOptionsArray.splice(index, 1);
			}
			this.selectedOptions = selectedOptionsArray;
			this.isOptionsWithinRange ? this.$emit('update-options', [...this.selectedOptions]) : this.$emit('update-options', null);
		}
	}
}
</script>
