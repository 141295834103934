import { copilotRepository } from "@/service/repository/copilotRepository";
import { showModal } from "@/utils";

export class WalkingService {
	constructor(store) {
		this.store = store;
	}

	async create(data) {
		let createWalkInResult = await copilotRepository.createWalkin(data);

		if (createWalkInResult.result === 0) {
			let reservation = createWalkInResult.reservation;

			const createdReservationElement = document.getElementById(`reservation-${reservation.id}`);
			if (createdReservationElement) {
				createdReservationElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}

			this.store.commit('copilot/setReservation', { reservation });

			this._openQRInvitation(reservation);
		}

		return createWalkInResult;
	}

	_openQRInvitation(reservation) {
		this.store.commit('copilot/setReservation', { reservation });

		const reservationElement = document.getElementById('reservation-' + reservation.id);

		const collapseElement = document.getElementById('collapse' + reservation.id);

		if (collapseElement) {
			const parentAccordion = collapseElement.closest('[data-bs-parent="#accordionAllServices"]');

			if (parentAccordion && !parentAccordion.classList.contains('show')) {
				parentAccordion.classList.add('show');
			}

			if (reservationElement) {
				window.scrollTo({
					top: reservationElement.offsetTop,
					behavior: 'smooth'
				});
			} else {
				console.error(`Reservation element with ID 'reservation-${reservation.id}' not found.`);
			}
		} else {
			console.error(`Collapse element with ID 'collapse${reservation.id}' not found.`);
		}

		// Abrir el modal después de un pequeño retraso
		setTimeout(() => {
			showModal('modalQr' + reservation.id); // Abre el modal con el ID correspondiente
		}, 0);
	}
}

export const walkingService = store => new WalkingService(store);
