<template src="./menuCartProduct.html"></template>

<script>
import {normalizeChoices} from "@/utils";
import {mapGetters} from "vuex";

export default {
	name: 'MenuCartProduct',
	emits: ['add-unit', 'subtract-unit'],
	props: ['product', 'isCopilotRoute','isActiveProductInputs'],
	data() {
		return {
			showPictureMenu: true,
			dishClassSelected: this.product.modifyDishClass?.id ||this.product.dishClass?.id,
			hasChangedDishClass: this.product.modifyDishClass,
		};
	},
	computed:{
		...mapGetters('venue', ['resourceId']),
		...mapGetters('menu',['productsCart', 'dishClasses']),
		productImg() {
			return `/world/img/${this.resourceId}/product/${this.product.pictureMenu}`;
		},
		productChosenOptionsIds() {
			if (!this.product.chosenOptions) {
				return '';
			}
			// Mapea para obtener los chosenIds, los aplana en un solo array, y luego los une en una cadena.
			return this.product.chosenOptions.map(option => option.chosenIds).flat().join('_');
		},
		productId() {
			return `flexCheckDishClass_${this.product.sku}_${this.productChosenOptionsIds}`;
		},
		alternativeDishClass(){
			return this.dishClassAvailable.filter(dishClass => dishClass.id !== this.product.dishClass.id)[0]
		},
		dishClassAvailable(){
			return this.dishClasses.filter(dishClass => dishClass.markableAs || dishClass.id === this.product.dishClass?.id);
		}
	},

	beforeMount() {
		this.checkImage();
		console.log({product:this.product});

		// this.product.activeCheckbox = this.product.dishClassChanged || (this.product.dishClass && this.product.dishClass.name === 'Primeros');
	},
	mounted() {
		console.log('Montando el producto el carrito', this.product);
	},
	methods: {
		handleImageError() {
			this.showPictureMenu = null;
		},
		imageUrl() {
			return this.productImg;
		},
		checkImage() {
			const img = new Image();
			img.src = this.productImg;

			img.onerror = () => {
				this.showPictureMenu = null;
			};

			this.showPictureMenu = true
		},
		getMinusButtonIcon() {
			return this.product.cartItemCount == 1 ? 'fa-trash' : 'fa-minus';
		},
		addUnit() {
			console.log({products:this.product})
			const normalizedChosenOptions = normalizeChoices(this.product.chosenOptions);
			console.log('Adding unit in cart for product:', this.product.sku, 'with options:', normalizedChosenOptions);
            this.$emit('add-unit', { ...this.product, chosenOptions: normalizedChosenOptions });
		},
		subtractUnit() {
			const normalizedChosenOptions = normalizeChoices(this.product.chosenOptions);
			console.log('Subtracting unit in cart for product:', this.product.sku, 'with options:', normalizedChosenOptions);
            this.$emit('subtract-unit', { ...this.product, chosenOptions: normalizedChosenOptions });
		},
		/*getOptionName(productOption, chosenOptionId) {
			const option = productOption.options.find(opt => opt.id === chosenOptionId);
			return option ? option.name : '';
		},*/
		formatChoices() {
			return Object.entries(this.product.chosenOptions).map(([optionId, chosenIds]) => {
				const productOption = this.product.productOptions.find(option => option.id === optionId);
				if (!productOption) {
					return '';
				}
				const chosenOptionNames = chosenIds
					.map(id => productOption.options.find(opt => opt.id === id)?.name)
					.filter(name => name);
				return `${productOption.description}: ${chosenOptionNames.join(', ')}`;
			}).join(' | ');
		},
		getOptionDescription(optionId) {
			const productOption = this.product.productOptions.find(option => option.id === optionId);
			return productOption ? productOption.description : '';
		},
		getOptionName(optionId, chosenId) {
			const productOption = this.product.productOptions.find(option => option.id === optionId);
			const option = productOption ? productOption.options.find(opt => opt.id === chosenId) : null;
			return option ? option.name : '';
		},
		handleCheckboxDishClass(isChecked) {
			const newDishClass = isChecked ? this.alternativeDishClass : this.product.dishClass
			this.$store.commit('menu/modifyDishClassOfCartProduct', {product: this.product, selectedDishClassId: newDishClass.id})
			this.dishClassSelected =  this.product.modifyDishClass?.id ||this.product.dishClass?.id;
		},

		handleChangeSelectDishClass(){
			this.$store.commit('menu/modifyDishClassOfCartProduct', {product: this.product, selectedDishClassId: this.dishClassSelected})
		}
	}
}
</script>
