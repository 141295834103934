<template>
	<label class="product-card-client product-card-client-list" :for="`option_checkbox_${order.name}-${order.productOrderId}`">
		<div class="product-card-body">
			<img v-if="showPictureMenu !== null && imageUrl()" class="product-card-img " :src="imageUrl()" @error="handleImageError" alt="...">
			<div class="product-card-info">
				<div class="product-card-data">
					<div class="product-card-title">
						<span v-if="order.quantity && source !== 'account' && !order.units">{{ order.quantity }}</span>
						<span v-if="source === 'account'">x{{ order.quantity }}</span>
						<span v-if="order.units > 1">{{ order.units }} (uds.)</span>
						<span v-if="order.count && order.count > 1">{{ order.count }}</span>
						{{ order.name }}
						<span v-if="source === 'account'">{{ order.price }}</span>
					</div>
				</div>
				<div class="ingredients">
					<div v-for="(choiceGroup, index) in order.choices" :key="index">
						<span>- {{ choiceGroup.choice }}</span>
					</div>
				</div>
			</div>

			<div class="product-card-btns flex-column">
				<div class="fw-bold" v-if="order.dishClassHasChanged">Como primero</div>

				<input v-if="showCheckbox" class="form-check-input" type="checkbox" :checked="isChecked(order.productOrderId)" :id="`option_checkbox_${order.name}-${order.productOrderId}`" :value="order.productOrderId" @change="handleCheckboxChange(order.productOrderId, $event)">

				<div v-if="source === 'orders' && order.statusProductOrder === 2" class="">
					<ChefHatIcon fill-color="black"/>
				</div>
				<div v-if="source === 'orders' && order.statusProductOrder === 3" class="">
					<i class="fa-solid fa-check"></i>
					<SpinnerIcon/>
				</div>
				<div v-if="source === 'orders' && order.statusProductOrder === 100" class="">
					<i class="fa-solid fa-check-double"></i>
				</div>

				<select v-if="showSelectAvailability" v-model="availability" :class="selectClass" class="form-select" style="width: 10rem; height: 2rem;" aria-label="Default select example">
					<option selected value="availability">Disponible</option>
					<option value="not-availability">No Disponible</option>
				</select>


<!--				<button v-if="source === 'orders' && order.dishClass && order.dishClassHasChanged" class="btn btn-outline-primary" @click="handleCheckboxDishClass">-->
<!--					{{ activeCheckboxDishClass ? 'Servir como segundo' : 'Servir como primero' }}-->
<!--				</button>-->
			</div>
		</div>
	</label>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {printChoices, showModal} from "@/utils";
import store from "@/store";
import ChefHatIcon from "@/core/icons/ChefHatIcon";
import SpinnerIcon from "@/core/icons/SpinnerIcon";

export default {
	name: 'OrdersProductCard',
	components: { SpinnerIcon, ChefHatIcon},
	inject: {
		reservationRepository: 'reservationRepository',
		userRepository: 'userRepository',
	},
	emits: ['add-unit', 'subtract-unit'],
	props: {
		order: {
			type: Object,
			required: true,
			default: null
		},
		source: {
			type: String,
			default: null
		},

		reservation: {
			type: Object,
			default: null
		},

	},
	data() {
		return {
			showPictureMenu: null,
			availability: 'availability',

			idModalMoveProductOrder: 'modal-confirm-dish-class-changed-' + this.order.name + '-' + this.order.productOrderId,
		};
	},
	computed: {
		...mapGetters('venue', ['resourceId', 'urlVenue']),
		...mapGetters('copilot', ['selectedProductOrdersToServe', 'selectedProductOrdersToPrepared','productOrder']),
		chosenOptions() {
			const counts = this.order.choices.reduce((acc, option) => {
				acc[option.choice] = (acc[option.choice] || 0) + 1;
				return acc;
			}, {});

			return Object.keys(counts).map(choice => ({
				choice,
				count: counts[choice]
			}));
		},
		productImg() {
			return `/world/img/${this.resourceId}/product/${this.order.pictureMenu}`;
		},
		productFoodImg() {
			return `/world/img/${this.resourceId}/product/${this.order.pictureMenu}`;
		},
		showCheckbox() {
			return this.source === 'readyOrder' || this.source === 'hybridOffcanvas'
		},
		showSelectAvailability() {
			return this.source === 'availability'
		},
		isChecked() {
			return (orderId) => {
				return this.selectedProductOrdersToServe.includes(orderId) || this.selectedProductOrdersToPrepared.includes(orderId);
			};
		},
		selectClass() {
			return this.availability === 'availability' ? 'availability' : 'not-availability';
		},
		activeCheckboxDishClass(){
			return this.order.dishClassHasChanged
		}
	},
	beforeMount() {
		store.commit('copilot/setSelectedProductOrdersToServe', {selectedProductOrdersToServe: []});
		store.commit('copilot/setSelectedProductOrdersToPrepared', {selectedProductOrdersToPrepared: []});
	},
	mounted() {
		this.checkImage();
		console.log({order: this.order})
	},
	methods: {
		...mapActions('copilot', ['getIdReservation','addProductOrder']),
		imageUrl() {
			return this.productImg;
		},
		checkImage() {
			const img = new Image();
			img.src = this.productImg;

			img.onerror = () => {
				this.showPictureMenu = null;
			};

			this.showPictureMenu = true
		},
		handleImageError() {
			this.showPictureMenu = null;
		},
		printChoices(choices) {
			return printChoices(choices)
		},
		handleClickProduct() {
			if (!this.source) {
				return
			}
			console.log(this.order.name)
		},
		handleCheckboxChange(productOrderId, event) {
			let selectedProductOrdersLocalArray = []

			if (this.source === 'readyOrder') {
				selectedProductOrdersLocalArray = [...this.selectedProductOrdersToServe];
			} else if (this.source === 'hybridOffcanvas') {
				selectedProductOrdersLocalArray = [...this.selectedProductOrdersToPrepared];
			}
			// Si el checkbox está marcado, añadimos el productOrderId al array

			if (event.target.checked) {
				if (!selectedProductOrdersLocalArray.includes(productOrderId)) {
					selectedProductOrdersLocalArray.push(productOrderId);
				}
			} else {
				selectedProductOrdersLocalArray = selectedProductOrdersLocalArray.filter(prodOrdId => prodOrdId !== productOrderId);
			}
			if (this.source === 'readyOrder') {
				store.commit('copilot/setSelectedProductOrdersToServe', {selectedProductOrdersToServe: selectedProductOrdersLocalArray});
				console.log('despues del handle', selectedProductOrdersLocalArray)
			} else if (this.source === 'hybridOffcanvas') {
				store.commit('copilot/setSelectedProductOrdersToPrepared', {selectedProductOrdersToPrepared: selectedProductOrdersLocalArray});
				console.log('despues del handle hybridOffcanvas', selectedProductOrdersLocalArray)
			}

		},
		async handleCheckboxDishClass() {
			await this.addProductOrder({productOrder: this.order});
			showModal(this.idModalMoveProductOrder);
		},

	}
}
</script>
