<template>
	<footer :class="{'p-3': isCopilotRoute || isKioskRoute}">
		<div class="info-footer">
			<div class="container">
				<div class="row row-column-gap">
					<div class="col-12">
						<div>
							© 2023-{{ currentYear }}
							<span class="border-bottom border-secondary" v-if="isKioskRoute">Wewelcom</span>
							<span v-else> <a href="https://wewelcom.io/" class="legal-warning" target="_blank"><b>Wewelcom</b></a> </span>
						</div>
						<div>
							<b>WE COPILOT HOSPITALITY</b>
						</div>
						<a v-if="!isCopilotRoute && !isKioskRoute" data-bs-toggle="modal" data-bs-target="#legal-terms-modal" class="legal-warning" target="_blank" rel="noopener noreferrer"> Aviso legal </a>
					</div>
				</div>
			</div>
		</div>
	</footer>

	<ModalAvisoLegal/>
</template>

<script>
import ModalAvisoLegal from "@/components/mods/modalAvisoLegal/ModalAvisoLegal";
export default {
	name: 'FooterWelcom',
	components: {
		ModalAvisoLegal,
	},
	data() {
		return {
			currentYear: new Date().getFullYear().toString(),
			isCopilotRoute: this.checkIfCopilotRoute(this.$route.path),
			isKioskRoute: this.checkIfKioskRoute(this.$route.path)
		};
	},
	watch: {
		'$route'(to) {
			this.isCopilotRoute = this.checkIfCopilotRoute(to.path);
			this.isKioskRoute = this.checkIfKioskRoute(to.path);
		}
	},
	beforeMount() {
	},
	mounted() {
		this.isCopilotRoute = this.checkIfCopilotRoute(this.$route.path);

		console.log(this.checkIfCopilotRoute(this.$route.path))
	},
	methods: {
		checkIfCopilotRoute(path) {
			return path === '/copilot';
		},
		checkIfKioskRoute(path) {
			return path === '/kiosk'
		}
	},
}
</script>
