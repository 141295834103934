<template>
	<div class="reservation-kitchen-content-product-list">
			<div v-if="dishClass.name" class="product-card-employee product-card-employee-secondary rotate-text">
				<div class="product-card-body">
					<div class="product-card-info">
						<div class="product-card-data">
							<h5 class="product-card-title">
								{{dishClass.name}}
							</h5>
						</div>
					</div>
				</div>
			</div>
			<CopilotProductCardKitchen
				v-for="product in productsOrder"
				:key="product.productOrderId"
				:product="product"/>
		</div>
</template>

<script>

import CopilotProductCardKitchen from "@/components/copilotProductCardKitchen/CopilotProductCardKitchen";


export default {
	name: 'CopilotProductsKitchen',
	components: {CopilotProductCardKitchen },
	props:{
		dishClass: {
			type: Object,
			required: true
		},
		productsOrder: {
			type: Array,
			required: true
		}
	},
	data() {
		return {

		}
	},
	watch: {

	},
	mounted() {

	},
	computed: {

	},
	methods: {

	}
}
</script>

