<template>
	<!--  Offcanvas historico de pedidos	-->
	<div v-if="reservation" class="offcanvas offcanvas-bottom" tabindex="-1" :id="'offcanvas-confirm-close-' + reservation.id" :aria-labelledby="'modalConfirmCloseLabel' + reservation.id" aria-hidden="true">
		<div class="offcanvas-header mb-0">
			<div class="offcanvas-title h4" :id="'modalConfirmClose' + reservation.id">	Pedir cuenta de {{ reservation.name }}</div>
			<button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
		</div>

		<div class="offcanvas-body p-3">
			<div class="row">
				<div class="col-12">
					<div class="service-meta">
						<span>Mesa:</span>
						<span>
							<TableIcon fill-color="black"/>
							{{ reservation.table === null ? '' : reservation.table }}
							<div class="vr mx-2"></div>
							{{ reservation.roomName === null ? '' : reservation.roomName }}
							</span>
					</div>
				</div>
				<div class="col-12" v-if="reservation.name">
					<div class="service-meta">
						<span>Nombre:</span>
						<span><i class="fas fa-walking"></i> {{ reservation.name }}</span>
					</div>
				</div>
				<div class="col-12">
					<div class="service-meta">
						<span>Pax:</span>
						<span>
								<i class="fas fa-users"></i> {{ reservation.pax }}
							</span>
					</div>
				</div>
				<div class="col-12">
					<div class="service-meta">
						Hora: <i class="fas fa-clock"></i> {{ reservation.startDate }}h
					</div>
				</div>
			</div>

			<div class="text-center my-2 fw-bold">Asegúrate de que sea la reserva que ha pedido la cuenta.</div>

			<div class="row">
				<div class="col-6">
					<button class="btn btn-primary w-100" @click="handelFinishReservation(0,1)" v-html="finishReservationTextButtonCash">
					</button>
				</div>
				<div class="col-6">
					<button class="btn btn-primary w-100" @click="handelFinishReservation(0,2)" v-html="finishReservationTextButtonCard">
					</button>
				</div>
			</div>

			<div v-if="pdfUrl">
				<iframe :src="pdfUrl" width="100%" height="600px"></iframe>
			</div>
		</div>

		<div class="offcanvas-footer">
			<DismissButton :is-modal="false" content-btn-text="Cancelar"/>

			<button v-if="!isReservationStatusStart" @click="handelFinishReservation(1)" class="btn btn-success">
				<span class="process-button">No show</span></button>
		</div>
	</div>
</template>
<script>
import {mapGetters} from "vuex";
import store from "@/store";

import {hideAllOffcanvas} from "@/utils";

import DismissButton from "@/core/DismissButton";
import TableIcon from "@/core/icons/TableIcon";

export default {
	name: "CopilotOffcanvasFinishReservation",
	inject: {
		reservationRepository: 'reservationRepository',
	},
	data() {
		return{
			finishReservationTextButtonCash: 'Pago en efectivo',
			finishReservationTextButtonCard: 'Pago con tarjeta',

			pdfUrl: null

		}
	},
	components:{
		TableIcon,
		DismissButton

	},
	computed:{
		...mapGetters('copilot',['reservation']),

		isReservationStatusStart() {
			return this.reservation.menuAccessEnabled === 1;
		},
	},
	mounted() {
	},
	methods: {
		async handelFinishReservation(noShow, paymentMethod) {
			try {
				if (paymentMethod === 1) {
					this.finishReservationTextButtonCash = `Pidiendo... <i class="fas fa-spinner fa-spin"></i>`
				}
				if (paymentMethod === 2) {
					this.finishReservationTextButtonCard = `Pidiendo... <i class="fas fa-spinner fa-spin"></i>`
				}


				const resultFinishReservation = await this.reservationRepository.finishReservation(this.reservation.id, noShow)

				await store.dispatch('menu/requestAccount', {
					reservationInfo: this.reservation,
					paymentMethod: paymentMethod
				});

				if (resultFinishReservation.result === 0) {
					// store.commit("copilot/setToast", {
					// 	toast: {
					// 		toastMessage: "Se ha finalizad la reserva correctamente",
					// 		showToast: true,
					// 		idToast: "correctlyToast"
					// 	}
					// });
					this.finishReservationTextButtonCash = 'Pago en efectivo';
					this.finishReservationTextButtonCard = 'Pago con tarjeta';
					hideAllOffcanvas();
				}
			} catch (e) {
				console.log(e)
			}
		},
	}
}
</script>
