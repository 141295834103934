<div id="welcom-menu" :data-bs-theme="resourceId">

	<!--  Header component	-->
	<HeaderComponent :class="{'pt-0':!isValidReservation && !isCopilotRoute}" v-if="!isCopilotRoute" :url-restaurant="weWelcomUrl" text-title="La carta" :subTitle="businessName" :image-restaurant="resourceId"/>

	<div v-if="activeMenu != null && isLoad">
		<!--  Informacion de la reserva: Mesa-->
		<div v-if="isValidReservation && !isCopilotRoute" class="navbar navbar-top navbar-client" style="padding: 0.75rem 1rem">
			<div class="container-lg">
				<!--				<div v-if="reservationInfo">Mesa {{ reservationInfo.table }}</div>-->
				<button class="btn btn-transparent" data-bs-toggle="offcanvas" data-bs-target="#offcanvas-bag-orders" aria-controls="offcanvas-bag-orders">
					<i class="fa-solid fa-clock-rotate-left"></i>
					Pedidos
				</button>
			</div>
		</div>

		<!--  Informacion de la reserva: Nombre e Invitados-->
		<div class="w-100" v-if="!isCopilotRoute">
			<div v-if="isValidReservation" class="info-inblock info-inblock-black">
				<div v-if="user.name && user">¡Hola {{ user.name }}!</div>
			</div>
			<div v-if="false" class="info-inblock info-inblock-black">
				<div id="guests" v-if="isCreator && invitedUsers.length || !isCreator && invitedUsers.length">
							<span v-for="(invited, index) in invitedUsers" :key="invited.id">
								<span v-if="invited.userId === user.id">Tú</span>
								<span v-if="invited.userId !== user.id">{{ invited.name }}</span>
								<span v-if="index !== invitedUsers.length - 1">, </span>
								<span v-if="index === invitedUsers.length - 1">.</span>
							</span>
				</div>
				<span v-else>
							No hay asistentes confirmados
						</span>
			</div>
		</div>

		<!--	Seccion de compartir	-->
		<section v-if="isValidReservation && !isCopilotRoute" class="section-primary section-not-rounded">
			<div class="section-header">
				<div class="section-title">Comparte tu carta a todo el grupo</div>
			</div>
			<div class="section-content">
				<div class="row">
					<div class="col-6">
						<button data-bs-toggle="modal" data-bs-target="#modalShareQR" class="btn btn-secondary w-100">QR</button>
					</div>
					<div class="col-6">
						<a :href="linkShareMenuWhatsapp" target="_blank" class="btn btn-secondary w-100">Whatsapp</a>
					</div>
				</div>
			</div>
		</section>

		<NavbarScrollCategories
			:active-menu="filteredMenus"
			:menus="menus"
			:product-unit="productsCart"
			:is-copilot-route="isCopilotRoute"
			@filter-menus="filterMenuCategoriesByProducts"
			:reservation-promotion="reservationInfo.promotion"
			@subtract-unit="subtractUnit"
			@add-product="addProductToCart"
			@add-unit="addUnit"
			:is-Valid-Reservation="isValidReservation"
		/>

		<!-- Recomendaciones del chef   -->
		<div v-if="!isCopilotRoute && recommendedProducts.length > 0 && !isOpenModalSearch" class="chef-recommendations">
			<div class="card-secction mb-0">
				<hr>
				Recomendaciones del chef
				<hr>
			</div>

			<div class="position-relative">
				<button class="scroll-arrow-welcom left-arrow" v-if="showLeftArrow" @click="scrollLeft">
					<span class="btn btn-secondary btn-circle"><i class="fas fa-chevron-left"></i></span>
				</button>
				<div ref="scrollContainer" class="scroll-x position-relative" @scroll="updateArrows">
					<menu-product-card
						class="product-card-client-vertical"
						v-for="product in recommendedProducts"
						:key="product.sku"
						:is-copilot-route="isCopilotRoute"
						:reservation-promotion="reservationInfo.promotion"
						:is-Valid-Reservation="isValidReservation"
						@add-product="addProductToCart"
						:product="product"
						@subtract-unit="subtractUnit"
						@add-unit="addUnit"
						:product-unit="productsCart"
					></menu-product-card>
				</div>
				<button class="scroll-arrow-welcom right-arrow" v-if="showRightArrow" @click="scrollRight">
					<span class="btn btn-secondary btn-circle"><i class="fas fa-chevron-right"></i></span>
				</button>
			</div>
		</div>

		<div v-if="menus" v-for="menu in filteredMenus" :key="menu.id" :id="menu.id + menu.name">
			<div v-if="menu.menuCategories" v-for="menuCategories in menu.menuCategories" :key="menuCategories.id" :id="menuCategories.id + '-' + menuCategories.name">
				<div class="card-secction" :class="isCopilotRoute? 'mb-0 p-1':''">
					{{ menuCategories.name }}
					<hr>
				</div>
				<div v-if="menuCategories.children.length" v-for="firtsChildren in menuCategories.children" :key="firtsChildren.id" :id="firtsChildren.id + '-' + firtsChildren.name">
					<div class="card-secction card-subsecction z-0" :class="isCopilotRoute? 'mb-0 p-1':''">
						<hr>
						{{ firtsChildren.name }}
						<hr>
					</div>
					<div v-if="firtsChildren.children.length" v-for="secondChildren in firtsChildren.children" :key="secondChildren.id" :id="secondChildren.id + '-' + secondChildren.name">
						<div class="container">
							<div class="row">
								<div class="col-12">
									<h3 class="card-secction card-subsecction z-0" :class="isCopilotRoute? 'mb-0 p-1':''">
										{{ secondChildren.name }}
									</h3>
								</div>
							</div>
						</div>
						<div class="container">
							<div v-if="secondChildren.description && !isCopilotRoute">
								<div class="card product-food text-center fst-italic fw-semibold">
									{{ secondChildren.description }}
								</div>
							</div>
							<div :class="isCopilotRoute ? 'row':'list-products'">
								<menu-product-card
									v-for="product in secondChildren.products"
									:key="product.sku"
									:is-copilot-route="isCopilotRoute"
									:reservation-promotion="reservationInfo.promotion"
									:is-Valid-Reservation="isValidReservation"
									@add-product="addProductToCart"
									:product="product"
									@subtract-unit="subtractUnit"
									@add-unit="addUnit"
									:product-unit="productsCart"
								></menu-product-card>
							</div>
						</div>
					</div>
					<div v-else class="container">
						<div v-if="firtsChildren.description && !isCopilotRoute">
							<div class="card product-food text-center fst-italic fw-semibold">
								{{ firtsChildren.description }}
							</div>
						</div>
						<div :class="isCopilotRoute ? 'row':'list-products'">
							<menu-product-card
								v-for="product in firtsChildren.products"
								:key="product.sku"
								:is-copilot-route="isCopilotRoute"
								:reservation-promotion="reservationInfo.promotion"
								:is-Valid-Reservation="isValidReservation"
								@add-product="addProductToCart"
								:product="product"
								@subtract-unit="subtractUnit"
								@add-unit="addUnit"
								:product-unit="productsCart"
							></menu-product-card>
						</div>
					</div>
				</div>
				<div v-else class="container">
					<div v-if="menuCategories.description && !isCopilotRoute">
						<div class="card product-food text-center fst-italic fw-semibold">
							{{ menuCategories.description }}
						</div>
					</div>
					<div :class="isCopilotRoute ? 'row':'list-products'">
						<menu-product-card
							v-for="product in menuCategories.products"
							:key="product.sku"
							:is-copilot-route="isCopilotRoute"
							:reservation-promotion="reservationInfo.promotion"
							:is-Valid-Reservation="isValidReservation"
							@add-product="addProductToCart"
							:product="product"
							@subtract-unit="subtractUnit"
							@add-unit="addUnit"
							:product-unit="productsCart"
						></menu-product-card>
					</div>
				</div>
			</div>
		</div>

		<!--Nav-Bottom-->
		<nav-bottom v-if="isLoad" :total-products="totalProducts" :active-menu="activeMenu" :is-valid-reservation="isValidReservation" :cart-session="reservationInfo.cartSession" :is-copilot-route="isCopilotRoute" :menus="menus"></nav-bottom>

	</div>
	<div v-else>
		<section class="text-center d-flex flex-column justify-content-center align-items-center" style="height: 70vh">
			<SpinnerIcon/>
		</section>
	</div>

	<footer-welcom v-if="!isCopilotRoute"></footer-welcom>

	<div class="m-4 p-4"></div>

	<!--Modal Message-->
	<modal-message :message="messageCart"></modal-message>

	<!-- Modal compartir por qr -->
	<div class="modal fade" id="modalShareQR" tabindex="-1" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h1 class="modal-title" id="share-menu-qr">
						<i class="fas fa-exclamation-circle"></i> Comparte el QR con tu invitado</h1>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">

					<div class="content-center">
						<generator-qr v-if="reservationInfo.id && user" :link="linkShareMenuQr" class="content-qr"></generator-qr>
					</div>

					<p class="text-center text-md-start">Muestra este código QR a tu invitado para que lo escanee y se una a la experiencia.</p>
				</div>
				<div class="modal-footer">
					<DismissButton/>
				</div>
			</div>
		</div>
	</div>

	<!--Modal solicitar cuenta	-->
	<menu-modal-request-account :reservation-info="reservationInfo"></menu-modal-request-account>

	<!--Modal star	-->
	<menuOffcanvasAssessment :reservation-info="reservationInfo" :historic-products="historicUnification"/>

	<MenuModalPaymentResult :reservation-info="reservationInfo"/>
	<!-- Modal del carrito -->
	<div class="modal fade" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" id="orders">
		<div class="modal-dialog modal-dialog-scrollable modal-fullscreen">
			<div class="modal-content d-flex flex-column">
				<div class="modal-header">
					<h1 class="modal-title fs-5" id="exampleModalLabel">Tu pedido</h1>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body d-flex flex-column flex-grow-1">

					<div v-if="totalProducts > 0" class="products-list-content overflow-auto" style="flex-grow: 1;">
						<div v-for="(group, dishClassName) in groupedProducts" :key="dishClassName">
							<p v-if="isActiveProductInputs" class="fw-bold text-uppercase">{{ dishClassName }}</p>
							<menu-cart-product
								v-for="(product,index) in group"
								:key="generateProductKey(product)"
								@subtract-unit="subtractUnit"
								@add-unit="addUnit"
								:product="product"
								:is-copilot-route="isCopilotRoute"
								:is-active-product-inputs="isActiveProductInputs"
								class="mb-2"
							></menu-cart-product>
						</div>
					</div>
					<div>
						<button class="btn btn-sm btn-outline-primary w-100 mb-3" @click="activateDishClassInputs" v-if="!isActiveProductInputs">¿Algún segundo de primero?</button>
						<div v-if="totalProducts > 0 && weOrderClientComments" class="form-floating">
							<textarea v-model="cartClientMessage" class="form-control" placeholder="¿Algún comentario de tu pedido?" id="comments"></textarea>
							<label for="comments">¿Algún comentario de tu pedido?</label>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<DismissButton/>
					<button
						v-if="totalProducts > 0"
						class="btn btn-primary"
						@click="handleConfirmCart"
					>
						<span v-html="menuButtonContentOrder"></span>
						<SpinnerIcon v-if="isSpinnerIconLoading"/>
					</button>
				</div>
			</div>
		</div>
	</div>

	<!--  Offcanvas BagOrders	-->
	<div id="offcanvas-bag-orders" aria-hidden="true" class="offcanvas offcanvas-bottom fade" tabindex="-1">
		<div class="offcanvas-header mb-0">
			<div id="bag-orders-label" class="offcanvas-title h4">
				<BagOrdersIcon/>
				Historial de pedidos
			</div>
			<button aria-label="Close" class="btn-close" data-bs-dismiss="offcanvas" type="button"></button>
		</div>
		<div class="offcanvas-body p-3">
			<ul class="nav nav-underline mb-3">
				<li class="nav-item">
					<button class="nav-link" :class="{ active: viewMode === 'unified' }" @click.stop="viewMode = 'unified'">Ver todo</button>
				</li>
				<li class="nav-item">
					<button class="nav-link" :class="{ active: viewMode === 'user' }" @click.stop="viewMode = 'user'">Ver por pedidos</button>
				</li>
			</ul>
			<transition name="fade" :duration="300" mode="out-in">
				<div v-if="viewMode === 'user'" key="user">
					<div class="orders-history bg-transparent" v-for="cartSessionOrder in processedCartSessionOrders" :key="cartSessionOrder.cartSessionOrderId">
						<div class="list-name">
							<div>
								<i class="fas fa-utensils"></i>{{ cartSessionOrder.userName }}
							</div>
						</div>
						<div class="extras-products-list">
							<orders-product-card :order="order" v-for="order in  groupedItems(cartSessionOrder)" :key="order.cartSessionOrderId"></orders-product-card>
						</div>
						<!--							<ul>-->
						<!--								<li v-for="item in groupedItems(cartSessionOrder)" :key="item.cartSessionOrderId">-->
						<!--									<div class="list-item">-->
						<!--										<div><b>{{ item.count }}</b></div>-->
						<!--										<div>{{ item.name }}</div>-->
						<!--									</div>-->
						<!--									<div v-html="printChoices(item.choices)"></div>-->
						<!--								</li>-->
						<!--							</ul>-->
					</div>
				</div>
				<div v-else key="unified">
					<div class="extras-products-list">
						<orders-product-card :order="order" v-for="order in historicUnification" :key="order.productSku"></orders-product-card>
					</div>
				</div>
			</transition>
		</div>
		<div class="offcanvas-footer">
			<DismissButton :is-modal="false"/>
		</div>
	</div>
	<modal-privacy></modal-privacy>
</div>
