<template src="./menuProductCard.html"/>

<script>
import * as bootstrap from 'bootstrap';
import {mapGetters} from "vuex";
// import store from "@/store";
import MenuProductCardOptions from "@/components/menuProductCardOptions/MenuProductCardOptions";
import {hideAllModals, normalizeChoices} from "@/utils";
import DismissButton from "@/core/DismissButton";
import {reactive} from "vue";
// import mapActions from "vuex/dist/vuex.mjs";

export default {
	name: 'MenuProductCard',
	emits: ['add-product', 'add-unit', 'subtract-unit'],
	props: {
		product: {
			type: Object,
			required: true
		},
		isValidReservation: {
			type: Boolean,
			required: false
		},
		reservationPromotion: {
			type: [Object, null],
			required: false
		},
		isCopilotRoute: {
			type: Boolean,
			required: true
		},
		productUnit: {
			type: Object,
		}
	},
	components: {MenuProductCardOptions, DismissButton},
	data() {
		return {
			pictureMenu: null,
			productImg: null,
			productFoodImg: null,
			selectedUnits: this.calculateValue(0),
			showPictureMenu: null,
			iSDisabledButtonAddCanvasDisable: true,
			options: reactive({}),
		};
	},
	beforeMount() {
		this.isLoading = true
		this.pictureMenu = `/world/img/${this.resourceId}/product/${this.product.pictureMenu}`
		this.productImg = `/world/img/${this.resourceId}/product/${this.product.pictureMenu}`
		this.productFoodImg = `/world/img/${this.resourceId}/product/${this.product.pictureMenu}`

		if (this.product.category === 'Soft' || this.product.subcategory === 'Beer' || this.product.subcategory === 'Wine') {
			// return this.product.finalPrice = this.product.priceMemberUnit * 100;

		} else {
			if (this.reservationPromotion && this.reservationPromotion.shortDescription) {
				// const discountPercentage = parseFloat(this.reservationPromotion.shortDescription.match(/\d+(\.\d+)?/));
				// discountFactor = discountPercentage ? (100 - discountPercentage) / 100 : 1;
			}
		}
		this.checkImage();

	},
	mounted() {
		if (this.hasProductOptions) {
			this.initializeOptions();
		}
	},
	computed: {
		...mapGetters('config', ['weWelcomUrl']),
		...mapGetters('venue', ['businessName', 'urlVenue', 'resourceId']),
		productRange() {
			const range = [];
			for (let i = this.product.servingQtyMin; i <= this.product.servingQtyMax; i++) {
				range.push(i);
			}
			return range;
		},
		hasProductOptions() {
			return Array.isArray(this.product.productOptions) && this.product.productOptions.length > 0;
		},
		productOptionsOld() {
			return this.product.productOptions ? this.product.productOptions[0] : []
		},
		isOptionsWithinRangeOld() {
			const options = [...this.options];
			if (this.hasProductOptions) {
				return options.length >= this.productOptions.min && options.length <= this.productOptions.max;
			}
			return true;
		},
		isOptionsWithinRange() {
			if (this.hasProductOptions) {
				return this.product.productOptions.every(option => {
					const selectedOptions = this.options[option.id] || [];
					return selectedOptions.length >= option.min && selectedOptions.length <= option.max;
				});
			}
			return true;
		},
		matchingProductUnits() {
			return this.productUnit.find(unit => unit.sku === this.product.sku) || {cartItemCount: 0};
		},
		backdropAccount() {
			return !this.isCopilotRoute;
		},
		availability() {
			return this.product.menuCategoryProductInfo.availability === 1
		}
	},
	inject: {
		dateFormatterService: 'dateFormatterService',
	},
	methods: {
		// ...mapActions('copilot',['addProductToCart','updateProductsListCommander']),
		checkImage() {
			const img = new Image();
			img.onerror = () => {
				console.log('Error 404: No se pudo cargar la imagen de fondo.');
				this.showPictureMenu = null;
			};
			this.showPictureMenu = true;

			img.src = this.pictureMenu;
		},
		initializeOptions() {
			if (Array.isArray(this.product.productOptions)) {
				this.product.productOptions.forEach(option => {
					this.options[option.id] = [];
				});
			} else {
				console.warn('productOptions is not an array or is undefined:', this.product.productOptions);
			}
		},
		calculateValue(increment) {
			return this.product.servingQtyMin + this.product.unitaryIncrement * increment;
		},
		calculateLabel(increment) {
			return this.product.servingQtyMin + this.product.unitaryIncrement * increment;
		},
		sendProductToCart(event) {
			if (event) {
				event.stopPropagation();
			}

			if (this.isCopilotRoute) {
				this.clearProductOptions();
				this.hideOffcanvas();
			}

			// store.commit("copilot/setToast", {toast:{toastMessage: `Tu ${this.product.name} se han agregado correctamente.`, showToast:true, idToast: "correctlyToast"}});
			const normalizedOptions = normalizeChoices(this.options);
			console.log('Emitting add-product with options:', normalizedOptions);
			this.$emit('add-product', this.product, 1, this.options);
			console.log('Unidades', this.product.cartItemCount)
			this.clearProductOptions();
			this.initializeOptions();
			this.hideOffcanvas();
		},
		clearProductOptions() {
			if (this.product.productOptions) {
				this.product.productOptions.forEach((productOption) => {
					const refName = `product_option_card_${this.product.sku}-${productOption.id}`;
					const ref = this.$refs[refName];
					if (ref && Array.isArray(ref)) {
						ref.forEach(r => r.clearSelectedOptions());
					} else if (ref) {
						ref.clearSelectedOptions();
					}
				});
			}

		},
		getImageAllergenUrl(alergeno) {
			return `assets/img/allergies/${alergeno.pictureName.toLowerCase()}.png`;
		},
		// formatPrice(price) {
		// 	if (isNaN(price)) {
		// 		return price;
		// 	}
		// 	const formatted = this.dateFormatterService.formattedPrice(price)
		// 	console.log(formatted)
		// 	return formatted
		// },
		formatFloat(price) {
			if (isNaN(price)) {
				return price;
			}
			const formatted = this.dateFormatterService.formattedPrice(price)
			return formatted
		},
		getMinusButtonIcon() {
			return this.product.cartItemCount === 1 ? 'fa-trash' : 'fa-minus';
		},
		openInformationProduct(productSku/*, event*/) {
			// if (event) {
			// 	event.stopPropagation();
			// }
			let offcanvasElement = document.getElementById('offcanvas_' + productSku);

			if (offcanvasElement) {
				let offcanvasInstance = bootstrap.Offcanvas.getInstance(offcanvasElement);
				if (!offcanvasInstance) {
					offcanvasInstance = new bootstrap.Offcanvas(offcanvasElement, {
						keyboard: true // Permite cerrar el offcanvas con la tecla ESC
					});
				}
				if (offcanvasElement.classList.contains('show')) {
					// offcanvasInstance.hide();
				} else {
					offcanvasInstance.show();
				}
			}
		},
		hideOffcanvas() {
			console.log('vamos a cerrar el canvas')
			const offcanvasElement = document.getElementById("offcanvas_" + this.product.sku);

			if (offcanvasElement) {
				const offCanvas = bootstrap.Offcanvas.getInstance(offcanvasElement);
				if (offCanvas) {
					offCanvas.hide();
				}
			}
		},
		updateOptionsOld(options) {
			options ? this.options = options : this.options = []
		},
		updateOptions(optionId, selectedOptions) {
			this.options[optionId] = selectedOptions;
		},
		addUnit() {
			if (event) {
				event.stopPropagation();
			}
			this.$emit('add-unit', this.matchingProductUnits)
		},
		subtractUnit() {
			if (event) {
				event.stopPropagation();
			}
			this.confirmSubtractUnit()
			// const modalElement = document.getElementById(id);
			//
			// if (modalElement) {
			// 	const modal = new bootstrap.Modal(modalElement, {
			// 		backdrop: this.backdropAccount
			// 	});
			// 	if (modal) {
			// 		modal.show();
			// 	}
			// }

			// showModal(id)

			// this.$emit('subtract-unit', this.matchingProductUnits)
		},
		confirmSubtractUnit() {
			if (event) {
				event.stopPropagation();
			}
			this.$emit('subtract-unit', this.matchingProductUnits);
			this.productToRemove = null;
			hideAllModals()
		},
		closeModal() {
			if (event) {
				event.stopPropagation();
			}
			hideAllModals()
		},
	}
}
</script>
